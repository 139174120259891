<template>
  <div class="GlobalTable">
    <GlobalTable
      ref="GlobalTable"
      v-loading="loading"
      :columns="tableColumns"
      :data="this.seachDataList"
      :pageSizes="[50,100]"
      :pageSize="page.pageSize"
      layout="total, sizes, prev, pager, next, jumper"
      :currentPage="page.pageNum"
      :total="this.page.total"
      @handleCurrentChange="handleCurrentChange"
      @handleSizeChange="handleSizeChange"
    >
      <el-table-column label="操作人" slot="operatorAccount" align="center">
        <template slot-scope="{ row }">
          {{row.operatorName}}-{{row.operatorAccount}}
        </template>
      </el-table-column>
      <el-table-column width="200" label="操作日志代码" slot="operation" align="center">
        <template slot-scope="{ row }">
          <el-link type="primary" @click="$refs['codeDia'].open(row)">查看</el-link>
        </template>
      </el-table-column>
    </GlobalTable>
    <CodeDia ref="codeDia" />
  </div>
</template>

<script>
import _api from "@/utils/request";
import CodeDia from './dialog/CodeDia.vue';
export default {
  name: "Table",
  components:{ CodeDia },
  data() {
    return {
      loading: false,
      page: {
        total: 0,
        pageNum: 1,
        pageSize: 50,
      },
      seachDataList: [],
      tableColumns: [
        { label: "操作时间", prop: "createTime" },
        { slotName: "operatorAccount" },
        { label: "操作模块", prop: "nodeName" },
        { label: "当前ip", prop: "ip" },
        { label: "当前ip地址", prop: "ipInfo" },
        { slotName: "operation" }
      ]
    };
  },
  computed: {
    SeachParams() {
      return this.$store.state.tagsView.SeachParams
    },
  },
  mounted() {
    this.handleCurrentChange();
  },
  watch:{
    SeachParams(newVal){
      console.log(newVal)
      this.handleCurrentChange()
    }
  },
  methods: {
    // 表格切换页面
    handleCurrentChange(val) {
      this.recoderpagenum=val||1
      const baseRequest = {
        operatorAccount: this.SeachParams.operatorAccount,
        nodeName: this.SeachParams.nodeName,
        startTime: this.SeachParams.startTime,
        endTime: this.SeachParams.endTime,
        pageNum: val,
        pageSize: this.page.pageSize,
      };
      this.$emit('handleCurrentChange',val)
      this.loading = true
      _api.getLogList(baseRequest).then(res => {
        if (res.code === 1) {
          this.seachDataList = res.data.records;
          this.page.total = res.data.total;
          this.page.pageNum = res.data.current;
          this.page.pageSize = res.data.size
          this.$store.commit("tagsView/POST_STAFF", this.seachDataList);
        }
      }).finally(() => {
        this.loading = false
        if (typeof this.SeachParams.disuseLoding === 'function') {
          this.SeachParams.disuseLoding();
        }
      });
    },
    handleSizeChange(num) {
      this.page.pageSize = num
      this.handleCurrentChange()
    },
  }
};
</script>

<style scoped lang="scss" ref="stylesheet/scss">

</style>
