<template>
  <el-dialog title="查看日志代码" :visible.sync="visible" :close-on-click-modal="false" width="550px">
    <pre><code>{{formateCode(row.arguments)}}</code></pre>
    <span slot="footer" class="dialog-footer">
      <el-button size="small" @click="visible = false">关闭</el-button>
    </span>
  </el-dialog>
</template>

<script>
import _api from "@/utils/request";
export default {
  name: 'CodeDia',
  data() {
    return {
      visible: false,
      row: {},
    }
  },
  methods: {
    open(row) {
      this.row = row
      this.visible = true
    },
    close() {
      this.visible = false
    },
    formateCode(code) {
      try {
        return JSON.stringify(JSON.parse(code), null, 2) 
      } catch (err) {
        return code
      }
    }
  }
}
</script>